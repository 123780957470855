.home_content-section {
    color: #849AA3;
    padding: 150px 0;
}
.home_content-section_googleMap {
    padding: 150px 0;
    width: 100% !important; 
}
.home_content-section_tech {
    padding: 160px 0;
    background-image: url(../../images/technologyExpirience.png);
    background-size: cover;
}
.home_content-section_contact {
    padding: 160px 0;
    background-image: url(../../images/aboutNew.png);
    background-size: cover;
}
.contentSection_whyUs_div {
    background-image: url(../../images/whyUsNew.png);
    background-size: cover;
}
.home_content-section_carousel {
    padding: 160px 0;
    background-size: cover;
}
.home_content-section_whyUs{
    padding: 160px 0;
    background: #849AA3;
    color: #fff;
}
.home_content-section_map {
    padding: 160px 0;
}
.contentSection_hero-row {
    align-items: center;
}
.contentSection_title {
    color: white;
    font-weight: 900 !important;
    margin-left: 145px !important;
}
.contentSection_cards{
    margin-top: 10px !important;
}
.contentSection-contact_title {
    color: #849AA3;
    font-weight: 900 !important;
}
.contentSection_text {
    color: white;
    font-weight: 600 !important;
    margin-top: 20px !important;
    cursor: pointer;
    width: 100%;
}
.contentSection-contact_text {
    color: #849AA3;
}
.content_row {
    display: flex;
    align-content: stretch;
}
.contentSection_col {
    flex-basis: 50%;
}
.contantSection_text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
}
.contentSection_top-line {
    color: #849AA3;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}
.content_heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #849AA3;
}
.dark {
    color: #1c2237;
}
.darkBg {
    background-color: #718891;
}
.lightBg {
    color: black;
}
.contentSection_subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
}
.contentSection_img-wrapper {
    max-width: 555px;
}
.content-img {
    /* max-width: 95%; */
    width: 100%;
    padding-right: 20px;
    height: auto;
    /* margin: 0 0 0 10px; */
}

.contentSection_img-wrapper img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.contentSection_btn {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: #849AA3;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
}
.contentSection_btn:hover {
    background-color: transparent;
    color: #fff;
}
@media screen and (max-width: 991px) {
    .contentSection_container {
        padding-right: 30px;
        padding-left: 30px;
    }
    .contentSection_title {
        color: white;
        font-weight: 900 !important;
        margin-left: 0 !important;
    }
}
@media screen and (max-width: 768px) {
    .contantSection_text-wrapper {
        padding-bottom: 65px;
    }
    .contentSection_col {
        max-width: 100%;
        flex-basis: 100%;
    }
    .contentSection_title {
        color: white;
        font-weight: 900 !important;
        margin-left: 0 !important;
    }
}
@media screen and (max-width: 768px) {
    .content-img {
        padding-right: 0;
    }
}