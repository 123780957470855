.addInvoice_container{
    padding: 20px;
    border-radius: 10px;
}
.addInvoice_title{
    font-size: 25px !important;
    position: relative !important;
    font-weight: 600 !important;
    color: #849BA4 !important;
}
.addInvoice_title::before{
    content: '' !important;
    position: absolute !important;
    left: 0 !important;
    bottom: 0 !important;
    height: 3px !important;
    width: 30px !important;
    background: linear-gradient(135deg, #849ba4, #c6dbe4) !important;
}
.addInvoice_saveButton{
    width: 80px;
    background: transparent;
    border: 1px solid #219653;
    height: 40px;
    border-radius: 40px;
    color: #219653;
}
.addInvoice_saveButton:hover{
    width: 80px;
    background: #219653;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.addInvoice_clearButton{
    width: 80px;
    background: transparent;
    border: 1px solid #dd3030;
    height: 40px;
    border-radius: 40px;
    color: #dd3030;
}
.addInvoice_clearButton:hover{
    width: 80px;
    background: #dd3030;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.addInvoice_checkbox-rabat{
    display: flex;
    margin-left: 1rem !important;
}
.addInvoice_checkbox-pdv{
    display: flex;
}
.addInvoice_checkbox-col{
    display: flex !important;
    align-self: center !important;
}
.InvoiceNumber_checkbox{
    margin-left: 5px !important;
    align-self: center !important;
}
.addInvoice_invoiceNumber{
    width: 25% !important;
}
@media(max-width: 600px){
    .addInvoice_inputGroup .addInvoice_invoiceNumber {
        width: 30% !important;
    }
}