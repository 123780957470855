.language_switch_header{
    border: none;
    color: white;
    width: 30px;
    background-size: 30px;
    background-color: transparent;
    vertical-align: middle;
  }
  .language_switch_header img {
    width: 30px;
  }
  .language_switch_header_rs{
    border: none;
    background-image: url(../../../images/serbia.png);
    background-repeat: no-repeat;
    margin-left: 10px;
  }
  .language_switch_header:hover{
    transform: scale(1.1) !important;
    transition: all 0.5 ease !important;
  }
  .button_header{
    border: 1px solid white;
    width: 100px;
    border-radius: 20px;

    background: white;
    color: #1a1d1e;
  }
  .button_header:hover{
    background: transparent;
    color: white;
  }