.contactUsPage_container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactUsPage_form {
    width: 100%;
    max-width: 820px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.contactUsPage_contact-form {
    background-color: #849AA3;
    position: relative;
}
.contactUsSection_circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #687f87);
    position: absolute;
}
.contactUsSection_circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
}
.contactUsSection_circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
}
.contactUsPage_contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #849AA3;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}
.contactUsPage_form {
    z-index: 10;
    overflow: hidden;
    position: relative;
}
.contactUsSection_form {
    padding: 2.3rem 2.2rem;
}
.contactUsPage_title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
}
.contactUsPage_input-container {
    position: relative;
    margin: 1rem 0;
}
.contactUsPage_input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
}
.contactUsPage_input-container textarea {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
}
.contactUsPage_input-container label {
    position: absolute;
    top: 50%;
    margin-top: 20px;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
}
.contactUsPage_input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
}
.contactUsSection_inputs {
    color: #687f87 !important;
    border-radius: 20px !important;
    border: 2px solid white !important;
}
.contactUsPage_btn {
    background-color: #fff !important;
    border: 2px solid #fafafa !important;
    border-radius: 25px !important;
    font-size: 0.95rem !important;
    color: #849AA3 !important;
}
.contactUsPage_btn:hover {
    background-color: transparent !important;
    color: #fff !important;
    border: 2px solid #fafafa;
}
.formControl_feedback {
    margin-top: 10px !important;
}
.contactUsPage_input-container span {
    left: 30px;
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
}
.contactUsPage_input-container span:before,
.contactUsPage_input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    height: 5px;
    transition: 0.3s;
    background-color: #849AA3;
    top: 50%;
    transform: translateY(-50%);
}
.contactUsPage_input-container span:before {
    left: 50%;
}
.contactUsPage_input-container span:after {
    right: 50%;
}
.contactUsPage_input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
}
.contactUsPage_input-container.focus span:before,
.contactUsPage_input-container.focus span:after {
    width: 50%;
    opacity: 1;
}
.contactUsPage_contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
}
.contactUsPage_contact-info .contactUsPage_title {
    color: #849AA3;
}
.contactUsPage_text {
    color: #333;
    margin: 1.5rem 0 2rem 0;
}
.contactUsPage_information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
}
.contactUsPage_information i {
    font-size: 28px;
    margin-right: 0.7rem;
}
.contactUsSection_social-media {
    padding: 2rem 0 0 0;
}
.contactUsSection_social-media p {
    color: #333;
}
.contactUsSection_social-icon {
    display: flex;
    margin-top: 0.5rem;
}
.contactUsSection_social-icon a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #849AA3, #687f87);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: .2s;
}
.contactUsSection_social-icon a:hover {
    transform: scale(1.05);
    color: white;
    background: white;
    border: 1px solid #849AA3;
}
.contactUsPage_contact-info:before {
    content: "";
    position: absolute;
    width: 150px;
    height: 120px;
    border: 20px solid #849AA3;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
}
.contactUsPage_big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(45deg, #849AA3, #687f87);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
    opacity: 0.8;
}
.contactUsPage_big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
}
.contactUsPage_square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 60%;
    bottom: 10%;
    transform: translate(170%, -60%);
    opacity: 0.3;
}
.contactUsPage_info {
    margin-top: 20px;
    cursor: pointer;
}
.contactUsSection_facebook:hover {
    color: #4267B2;
    transform: scale(1.4);
    transition: 0.2s ease-in-out;
}
.contactUsSection_instagram:hover {
    color: #d6249f;
    transform: scale(1.4);
    transition: 0.2s ease-in-out;
}
.contactUsSection_linkedIn:hover {
    color: #2867B2;
    transform: scale(1.4);
    transition: 0.2s ease-in-out;
}
@media (max-width: 850px) {
    .contactUsPage_form {
        grid-template-columns: 1fr;
    }
    .contactUsPage_contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
    }
    .contactUsPage_contact-form:before {
        top: -13px;
        left: initial;
        right: 70px;
    }
    .contactUsPage_square {
        transform: translate(140%, 43%);
        height: 350px;
    }
    .contactUsPage_big-circle {
        bottom: 75%;
        transform: scale(0.9) translate(-40%, 30%);
        right: 50%;
    }
    .contactUsPage_text {
        margin: 1rem 0 1.5rem 0;
    }
    .contactUsSection_social-media {
        padding: 1.5rem 0 0 0;
    }
}
@media (max-width: 480px) {
    .contactUsPage_container {
        padding: 1.5rem;
    }
    .contactUsPage_contact-info:before {
        display: none;
    }
    .contactUsPage_square,
    .contactUsPage_big-circle {
        display: none;
    }
    .contactUsPage_contact-info {
        padding: 1.7rem 1.6rem;
    }
    .contactUsPage_text,
    .contactUsPage_information,
    .contactUsSection_social-media p {
        font-size: 0.8rem;
    }
    .contactUsPage_title {
        font-size: 1.15rem;
    }
    .contactUsSection_social-icon a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .contactUsPage_information i {
        width: 23px;
    }
    .contactUsPage_input {
        padding: 0.45rem 1.2rem;
    }
    .contactUsPage_btn {
        padding: 0.45rem 1.2rem;
    }
};