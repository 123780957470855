.carousel_item{
  width: 500px;
}

@media screen and (max-width: 400px) {
  .carousel_item{
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .carousel_item{
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .carousel_item{
    width: 100%;
  }
}