.react_modal_title {
    display: inline-flex !important;
    margin-right: 200px !important;
    margin-left: 20px !important;
}

.react_modal_logo_img {
    width: 50px !important;
    height: 50px !important;
    padding: 5px !important;
}

.react_dialog_title {
    color: #849BA4 !important;
    justify-content: flex-start !important;
}

.react_dialog_content {
    color: #849BA4 !important;
}

.react_modal_typography {
    color: #849BA4 !important;
    font-style: italic !important;
    font-weight: bold !important;
}

.react_modal_img_bottom {
    width: 300px !important;
}

.react_modal_button {
    float: right !important;
    border-radius: 20px !important;
}

.react_modal_button:hover {
    color: #849BA4 !important;
}

.react_modal_dialogActions {
    width: 100% !important;
}

@media screen and (max-width: 400px) {
    .react_modal_title {
        display: inline-flex !important;
        margin-right: 0 !important;
        margin-left: 0;
    }
}

@media screen and (max-width: 600px) {
    .react_modal_title {
        display: inline-flex !important;
        margin-right: 0 !important;
        margin-left: 20px !important;
    }
}