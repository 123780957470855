.language_switch_loginPage{
    border: none;
    color: white;
    width: 30px;
    background-size: 30px;
    background-color: transparent;
    vertical-align: middle;
  }
  .language_switch_loginPage img {
    width: 30px;
  }
  .language_switch_loginPage_rs{
    border: none;
    background-image: url(../../images/serbia.png);
    background-repeat: no-repeat;
    margin-left: 10px;
  }
  .language_switch_loginPage:hover{
    transform: scale(1.1) !important;
    transition: all 0.5 ease !important;
  }
  .loginForm_back_text-muted{
    color: #b1b1b1;
    cursor: pointer;
  }