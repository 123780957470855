.footer-container {
  background-color: #1a1d1e !important;
  padding: 4rem 0 2rem 0 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}
.footer_button {
  border: 2px solid rgb(209, 209, 209) !important;
  border-radius: 20px !important;
  color: rgb(209, 209, 209) !important;
  cursor: pointer !important;
  background: transparent !important;
  height: 100% !important;
}
.footer_inputArea_grid{
  padding-top: 20px !important;
}
.footer_button:hover {
  color: #242424 !important;
  background: rgb(209, 209, 209) !important;
}
.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}
.footer-subscription>p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}
.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}
.footer-input {
  padding: 8px 20px;
  border-radius: 20px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}
.footer-links {
  width: 100% !important;
  max-width: 1000px !important;
  display: flex !important;
  justify-content: center !important;
}
.footer-link-wrapper {
  display: flex;
}
.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  box-sizing: border-box;
}
.footer-link-items h2 {
  margin-bottom: 16px;
}
.footer-link-items>h2 {
  color: #fff;
}
.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}
.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}
.footer-email-form h2 {
  margin-bottom: 2rem;
}
.footer-input::placeholder {
  color: #b1b1b1;
}
.social-icon-link {
  color: #fff;
  font-size: 24px;
}
.social-media {
  max-width: 100%;
  width: 100%;
}
.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}
.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}
.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.insta_icon {
  color: white;
  font-size: 24px;
}
.linkedIn_icon {
  color: white;
  font-size: 24px;
}
.facebook_icon {
  color: white;
  font-size: 24px;
}
.insta_icon:hover {
  color: #d6249f;
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}
.linkedIn_icon:hover {
  color: #2867B2;
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}
.facebook_icon:hover {
  color: #4267B2;
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}
.website-rights {
  color: #fff;
}
@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  .footer-input {
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .social-media-wrap {
    flex-direction: column;
  }
  .footer_website-rights_grid{
    margin-top: 10px !important;
  }
  .social-icons{
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .footer-link-items {
    padding-top: 10px !important;
  }
  .footer_website-rights_grid{
    margin-top: 10px !important;
  }
  .social-icons{
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 400px) {
  .footer_website-rights_grid{
    margin-top: 10px !important;
  }
  .social-icons{
    margin-top: 10px !important;
  }
}