.muted_bold{
    font-weight: bold;
    cursor: pointer;
    margin-left: 3px;
}
.muted_bold:hover{
    color: #849BA4;
}
.muted_bold:active{
    color: #849BA4;
}
.loginForm_text-muted{
    color:#b1b1b1 !important;
    cursor: pointer;
}
.loginForm_text-muted:hover{
    cursor: pointer;
}