.passwordChange_input{
    border-radius: 40px !important;
}
.passwordChange_saveButton{
    width: 80px;
    background: transparent;
    border: 1px solid #219653;
    height: 40px;
    border-radius: 40px;
    color: #219653;
}
.passwordChange_saveButton:hover{
    width: 80px;
    background: #219653;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.passwordChange_clearButton{
    width: 80px;
    background: transparent;
    border: 1px solid #dd3030;
    height: 40px;
    border-radius: 40px;
    color: #dd3030;
}
.passwordChange_clearButton:hover{
    width: 80px;
    background: #dd3030;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}