.profileCard_icon{
    list-style: none;
    color: #849BA4 !important;
}
.profileCard_hr{
    color: #849BA4 !important;
}
.profileCard_profileImg{
    background-color: #849BA4;
    border-radius: 40px;
}
.profileCard_label{
    display: flex;
}
.profileCard_label h6{
    margin-left: 0.5rem;
}