.monthModal_dashboard-saveButton{
    width: 80px;
    background: transparent;
    border: 1px solid #219653;
    height: 40px;
    border-radius: 40px;
    color: #219653;
}
.monthModal_dashboard-saveButton:hover{
    width: 80px;
    background: #219653;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.monthModal_dashboard-closeButton{
    width: 80px;
    background: transparent;
    border: 1px solid #849AA3;
    height: 40px;
    border-radius: 40px;
    color: #849AA3;
}
.monthModal_dashboard-closeButton:hover{
    width: 80px;
    background: #849AA3;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.monthInvoices_dashboard-header{
    border-bottom: 0 none !important;
}
.monthInvoices_dashboard-footer{
    border-top: 0 none !important;
}