.dasboard_logo{
    width: 200px;
    justify-content: center;
    margin-top: 10px;
}
.dashboard_container{
    position: relative;
    width: 100%;
}
.dashboard_navigation{
    position: fixed;
    width: 300px;
    height: 100%;
    background: #1a1d1e;
    border-left: 10px solid var(#000);
    transition: 0.5s;
    overflow: hidden;
    left: 0;
}
.dashboard_navigation ul{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.dashboard_navigation ul li{
    position: relative;
    width: 100%;
    list-style: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.dashboard_navigation ul li:hover{
    background: white;
}
.dashboard_navigation ul li:nth-child(1){
    margin-bottom: 40px;
    pointer-events: none;
}
.dashboard_navigation ul li a{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: white;
}
.dashboard_navigation ul li:hover a{
    color: #1a1d1e;
}
.dashboard_navigation ul li a .dashboard_icon{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.dashboard_icon-mobile{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.dashboard_navigation ul li a .dashboard_icon .dashboard_fas{
    font-size: 1.75em;
}
.dashboard_navigation ul li a .dashboard_title{
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
}
.dashboard_navigation ul li:hover a::before{
    content: '';
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px white;
    pointer-events: none;
}
.dashboard_navigation ul li:hover a::after{
    content: '';
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px white;
    pointer-events: none;
}
.dashboard_main{
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: white;
    transition: 0.5s;
}
.dashboard_topbar{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.dashboard_toggle{
    position: relative;
    top: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    cursor: pointer;
}
.dashboard_search{
    position: relative;
    width: 400px;
    margin: 0 10px;
}
.dashboard_search label{
    position: relative;
    width: 100%;
}
.dashboard_search label input{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 35px;
    padding: 5px 20px;
    outline: none;
    border: 1px solid gray;
}
 .dashboard_iconSearch{
    position: absolute;
    margin-top: 10px;
    left: 10px;
    font-size: 1.2rem;
}
.dashboard_user{
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
.dashboard_user img{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.dashboard_cardBox{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
    align-items: center;
    justify-content: center;
}
.dashboard_cardBox .dashboard_card{
    position: relative;
    background: white;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 7px 25px rgba(0,0,0,0.2);
}
.dashboard_cardBox .dashboard_card .dashboard_numbers{
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: #849BA4;
}
.dashboard_cardBox .dashboard_card .dashboard_cardName{
    color: gray;
    font-size: 1.1em;
    margin-top: 5px;
}
.dashboard_cardBox .dashboard_card .dashboard_iconBx{
    font-size: 3.5em;
    color: gray;
}
.dashboard_cardBox .dashboard_card:hover{
    background: #849BA4;
}
.dashboard_cardBox .dashboard_card:hover .dashboard_numbers,
.dashboard_cardBox .dashboard_card:hover .dashboard_cardName,
.dashboard_cardBox .dashboard_card:hover .dashboard_iconBx{
    color: white;
}
.dashboard_details{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    margin-top: 10px;
}
.dashboard_details-profile{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-gap: 30px;
    margin-top: 10px;
}
.dashboard_details .dashboard_recentOrders{
    position: relative;
    display: grid;
    min-height: 500px;
    background: white;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.2);
    border-radius: 10px;
}
.dashboard_details-profile .dashboard_recentOrders-profile{
    position: relative;
    display: grid;
    min-height: 500px;
    background: white;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.2);
    border-radius: 10px;
    width: 100%;
}
.dashboard_cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.dashboard_cardHeader h2{
    font-weight: 600;
    color: #849BA4;
}
.dashboard_btn{
    position: relative;
    padding: 5px 10px;
    background: #849BA4;
    text-decoration: none;
    color: white;
    border-radius: 10px;
}
.dashboard_details table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.dashboard_details table thead td{
    font-weight: 600;
}
.dashboard_details .dashboard_recentOrders table tr{
    color: black;
    border-bottom: 1px solid rgba(29, 29, 29, 0.1);
}
.dashboard_details .dashboard_recentOrders table tr:last-child{
    border-bottom: none;
}
.dashboard_details .dashboard_recentOrders tbody tr:hover{
    background: #849BA4;
    color: white;
}
.dashboard_details .dashboard_recentOrders table tr td{
    padding: 10px;
}
.dashboard_details .dashboard_recentOrders table tr td:last-child{
    text-align: end;
}
.dashboard_details .dashboard_recentOrders table tr td:nth-child(2){
    text-align: end;
}
.dashboard_details .dashboard_recentOrders table tr td:nth-child(3){
    text-align: center;
}
.status .delivered{
    padding: 2px 4px;
    background: greenyellow;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}
.recentInvoicing{
    position: relative;
    display: grid;
    min-height: 500px;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.2);
    border-radius: 10px;
}
.recentInvoicing .newInvoicing_imgBx{
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.recentInvoicing .newInvoicing_imgBx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.recentInvoicing .newInvoicing_table .newInvoicing_tr:hover{
    background: #849BA4;
    color: white;
}
.recentInvoicing .newInvoicing_table .newInvoicing_tr .newInvoicing_td{
    padding: 12px 10px;
}
@media(max-width:991px){
    .dashboard_navigation{
        left: -300px;
    }
    .dashboard_main{
        width: 100%;
        left: 0;
    }
    .dashboard_cardBox{
        grid-template-columns: repeat(1, 1fr);
    }
}
@media(max-width: 768px){
    .dashboard_details{
        grid-template-columns: repeat(1, 1fr);
    }
    .dashboard_recentOrders{
        overflow-x: auto;
    }
    .dashboard_language{
        display: none;
    }
    .dashboard_search{
        width: 200px !important;
        text-align: center !important;
        margin-left: 2rem;
    }
}
@media(max-width:480px){
    .dashboard_cardBox{
        grid-template-columns: repeat(1,1fr);
    }
    .dashboard_cardHeader h2{
        font-size: 20px;
    }
    .dashboard_user{
        min-width: 40px;
    }
    .dashboard_navigation{
        width: 100%;
        left: -100%;
        z-index: 1000;
    }
    .dashboard_search{
        width: 200px !important;
        text-align: center !important;
        margin-left: 2rem;
    }
    .dashboard_language{
        display: none;
    }
}