.smartInvoicing_body{
    background-color: #d0c0b4;
    font-size: 12px;
    color: white;
    background: transparent url(../../images/underBackground.png) 0 0 no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}
.smartInvoicing_social-links a{
    text-decoration: none;
    cursor: pointer;
    color: white;
}
.smartInvoicing_social-links a:hover{
    transform: scale(1.1);
    color: white;
    transition: all 0.2s;
}
.smartInvoicing_main{
    width: 100%;
    position: relative;
    z-index: 100;
    color: white;
}
.smartInvoicing_main img{
    margin: 20px 0 0 0;
    width: 200px;
}
.smartInvoicing_main header{
    padding: 70px 0;
}
.smartInvoicing_footer{
    text-align: center;
    padding-bottom: 80px;
}
.smartInvoicing_social-links{
    display: flex;
    justify-content: center;
}   
.smartInvoicing_social-links a{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    width: 40px;
    font-size: 20px;
    border: 2px solid white;
    border-radius: 15px 20px 5px
}
.backToHome h3{
    text-align: center;
    cursor: pointer;
}
.backToHome h3:hover{
    color: #849BA4;
}
.smartInvoicing_logo{
    padding: 20px;
    cursor: pointer;
}
.smartInvoicing_title{
    text-align: center;
    padding-bottom: 80px;
}
.language_switch_smartInvoicing{
    border: none;
    color: white;
    width: 30px;
    background-size: 30px;
    background-color: transparent;
    vertical-align: middle;
  }
  .language_switch_smartInvoicing img {
    width: 30px;
  }
  .language_switch_smartInvoicing_rs{
    border: none;
    background-image: url(../../images/serbia.png);
    background-repeat: no-repeat;
    margin-left: 10px;
  }
  .language_switch_smartInvoicing:hover{
    transform: scale(1.1) !important;
    transition: all 0.5 ease !important;
  }
  .insta_icon:hover{
    color: #d6249f;
    transform: scale(1.1);
    transition: 0.2s ease-in-out ;
  }
  .linkedIn_icon:hover{
    color: #2867B2;
    transform: scale(1.1);
    transition: 0.2s ease-in-out ;
  }
  .facebook_icon:hover{
    color: #4267B2;
    transform: scale(1.1);
    transition: 0.2s ease-in-out ;
  }
@media screen and (max-width: 768px) {
    .smartInvoicing_logo{
        text-align: center;
    }
    
}