.slider_header{
    height: 100vh;
    width: 100%;
    padding: 0 8px;
    background: #080008;
    position: relative;
}
.slider_nav-links{
    flex: 1;
    text-align: center;
}
.slider_nav-links li{
    display: inline-block;
    margin: 0 15px;
}
.slider_nav-links li a{
    text-decoration: none;
    color: #fff;
    padding: 5px 0
}
.slider_btn{
    background: transparent;
    border: 1px solid gray;
    border-radius: 25px;
    padding: 10px;
    color: white;
    width: 12rem;
    height: 3rem;
    margin-top: 2.5rem;
}
.slider_btn:hover{
    background-color: #849BA4;
    transition: 0.2s;
}
.slider_content{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    max-width: 900px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    padding-top: 50px;
}
.slider-caption{
    letter-spacing: -0.05em;
}
.slider-description{
    font-size: 40px;
    font-weight: bold;
}
.slider_content h1{
    font-size: 65px;
    font-weight: 600;
    margin-bottom: 40px;
}
.slider_content form{
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
}
.slider_content input{
    flex: 1;
    border: none;
    outline: none;
    padding: 0 20px;
    font-size: 18px;
}
.slider_content form button{
    background: #849BA4;
    color: #fff;
    padding: 15px 40px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}
.slider_category-list{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.slider_category{
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.15);
    margin: 0 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
}
.slider_category img{
    width: 25px;
    margin-bottom: 5px;
}
.slider_back-video{
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto !important;
    height: auto;
    filter: blur(10px);
    -webkit-filter: blur(8px);
}
  .slider_nav-header{
      background-color: transparent;
      color: white;
      display: flex;
      align-items: baseline;
      padding: .5rem;
      gap: 1rem;
  }
  .slider_link{
      background: none;
      border: none;
      text-decoration: none;
      color: rgb(255, 255, 255);
      font-family: inherit;
      font-size: inherit;
      cursor: pointer;
      padding: 0;
  }
  .slider_link:hover{
      color: black;
  }
  .slider_dropdown{
    position: relative;
  }
  .slider_dropdown-menu{
      position: absolute;
      left: 0;
      top: calc(100% + .25rem);
      background-color: white;
      padding: .75rem;
      border-radius: .25rem;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
      opacity: 0;
      transform: translateY(-10px);
      transition: opacity 150ms ease-in-out;
  }
.slider_dropdown > .link:focus + .slider_dropdown-menu{
    opacity: 1;
    transform: translateY(-10px);
}