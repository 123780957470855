.about-area{
    padding: 8rem 5rem;
    background: white;
}
.about-button{
    font-size: 22px !important;
    text-decoration: none !important;
    display: inline-block !important;
    padding: 12px 35px !important;
    background-color: #849BA4 !important;
    border-radius: 25px !important;
    color: #fff !important;
    border: 1px solid #849BA4 !important;
    letter-spacing: 1.5px !important;
}
.about-img{
    align-items: center;
    justify-content: center;
    text-align: center;
}
.about-img img{
    width: 60%;
}
.about-button:hover{
    background-color: #fff !important;
    color: #849BA4 !important;
    transition: 0.2s ease !important;
}
.about-area .about-title h2>span{
    display: block;
    font: normal bold 49px/60px;
    letter-spacing: 15px;
}
.about-area .about-title .paragraph>p{
    padding: .4rem 0;
}
p.para{
    color: rgb(114, 114, 114);
    font: normal 500 16px/25px;
}
@media screen and (max-width: 900px) {
    .about-area{
        grid-template-columns: repeat(1, 1fr);
    }
    .about-img{
        display: none;
    }
}