.modal-content{
    border-radius: 40px;
}
.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
}
.popup{
    width: 350px;
    height: 280px;
    padding: 30px 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 2;
    text-align: center;
}
.modal_title{
    font-weight: 600;
    color: white;
}
.allInvoicesModal_dashboard-saveButton{
    width: 80px;
    background: transparent;
    border: 1px solid #219653;
    height: 40px;
    border-radius: 40px;
    color: #219653;
}
.allInvoicesModal_dashboard-saveButton:hover{
    width: 80px;
    background: #219653;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.allInvoicesModal_dashboard-closeButton{
    width: 80px;
    background: transparent;
    border: 1px solid #849AA3;
    height: 40px;
    border-radius: 40px;
    color: #849AA3;
}
.allInvoicesModal_dashboard-closeButton:hover{
    width: 80px;
    background: #849AA3;
    border: 1px solid gray;
    height: 40px;
    border-radius: 40px;
    color: white;
}
.allInvoicesModal_modal{
    border-radius: 40px !important;
}
.allInvoices_dashboard-header{
    border-bottom: 0 none !important;
}
.allInvoices_dashboard-footer{
    border-top: 0 none !important;
}