.dynamicField_icon{
    float: right;
}
.dynamicField_icon{
    font-size: 25px;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    color: #849ba4;
    cursor: pointer;
    align-self: center;
}
.dynamicField_icon-remove{
    font-size: 15px;
    align-self: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    color: rgb(90, 90, 90);
}
.dynamicField_input{
    display: flex;
}
.dynamicField_icon-add{
    font-size: 2rem;
    color: #849ba4;
    cursor: pointer;
}
.dynamicField_helperText{
    cursor: pointer;
}
.dynamicField_div{
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
}
.dynamicFormField_checkbox{
    display: flex;
}
.dynamicFormField_checkbox-form{
    display: flex;
}
.dynamicFormField_icons{
    align-self: center;
    margin-left: -15px;
}
.dynamicForm_checkBox{
    margin-left: 5px;
}
.dynamicFormField_small{
    width: 40% !important;
    display: flex !important;
    align-items: flex-end !important;
}
.dynamicFormField_medium{
    width: 60% !important;
} 
.dynamicField_icon-add-hidden{
    display: none;
}
.dynamicForm_inputs {
    margin-top: 0.5rem !important;
    min-width: 400px !important;
}
.header_label {
    height: 35px;
}
.invoiceRows_nameOfService::-webkit-scrollbar {
    width: 5px;
}
.dynamicFormField_hidde{
    display: none !important;
}
@media(max-width: 600px){
    .invoiceRows_table .invoiceRows_nameOfService {
        width: 15rem;
        overflow-y: scroll;
    }
    .invoiceRows_table .invoiceRow_price{
        width: 5rem !important;
    }
    .invoiceRows_table .invoiceRow_rabat{
        width: 3rem !important;
    }
}