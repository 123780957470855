.characters {
    list-style: none;
    padding-left: 0;
}
.characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
}
.characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
}
.characters-thumb {
    display: flex;
    overflow: hidden;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
}
.characters-thumb img {
    display: flex;
    width: 100%;
    height: auto;
}