.logoBar_main {
  display: inline-block;
  min-height: 250px;
  text-align: center;
  padding: 150px;
}
.logoBar_img img {
  width: 200px;
  display: inline-block;
}
@media screen and (max-width: 400px) {
  .logoBar_main {
    text-align: center;
    padding: 20px;
  }
  .logoBar_img img {
    display: inline-block;
    margin-top: 20px;
  }
}